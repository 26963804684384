import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import FlexCard from '../components/flexcard';
import GiftCardBackground from '../components/giftcardbackground';
import settings from '../../settings';


// images
const topbanner = `${settings.IMAGES_BASE_URL}/images/creditcard/bmw-banner.jpg`;
const creditCard = `${settings.IMAGES_BASE_URL}/images/home/bmw-cc-home.jpg`
const CreditCard = () => {
  return (
    <Layout>
      <SEO title="BMW Performance Driving School" description="Learn total car control behind the wheel of a BMW at the BMW Performance Driving School. Multiple class offerings and challenges await in one of our three locations." />
      <div className="giftcardpage giftcardpagemini">
        <GiftCardBackground
          imageDesktop={topbanner}
          imageMobile={topbanner}
          imageAlt="BMW Gift Card Top Banner"
        />
        <section className="drivingschool performance-center">
          <FlexCard
            rowType="row"
            image={creditCard}
            imageAlt="REWARDS FOR THE DRIVEN"
            header="REWARDS FOR THE DRIVEN"
            subheader="GET 20% OFF WITH YOUR BMW CARD"
            btnText="Learn more about the BMW card"
            btnStyle="btn-blue"
            externalURL="https://www.mybmwcreditcard.com/offer50"
          >
            <p>
              Accelerate your rewards with the BMW Card – exclusively for BMW drivers. Redeem for unlimited cash back toward your BMW Financial Services monthly payment or travel. Plus, you’ll save 20% when you book a class with us over the phone.
              <br/><br/>
              Excludes the M4 GT4 Experience, Race License School, M Summer Competition, M Mixed Reality and Private Instruction.
            </p>
          </FlexCard>
        </section>
      </div>
    </Layout>
  );
};

export default CreditCard;
